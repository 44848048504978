<template>
    <div class="mod-city-select">
        <el-form-item class="col-3">
            <el-select v-model="province">
                <el-option label="请选择" value=""></el-option>
                <el-option v-for="(value, key) in provincesList" :label="value" :value="key" :key="'opt1_' + key"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item class="col-3" :required="true" prop="city">
            <el-select v-model="city" placeholder="请选择" @change="handleCityChange">
                <el-option label="请选择" value=""></el-option>
                <el-option v-for="(item, index) in citiesList" :label="item.text" :value="item.id" :key="'opt2_' + index"></el-option>
            </el-select>
        </el-form-item>
        <div class="hot-city">
            <span v-for="(city, key) in hotCityMap" :key="key" @click="selectHotCity(city.value)">{{city.label}}</span>
        </div>
    </div>
</template>

<script>
    import { getProvince, getCitiesByProvince, getProvinceByCity } from '#/js/util/provinceCity.js';
    import hotCityMap from '#/js/config/hotCity.json';

    export default {
        name: "citySelect",
        data() {
            return {
                hotCityMap: hotCityMap,
                province: '',
                provincesList: getProvince(),
                city: '',
                citiesList: []
            }
        },
        watch: {
            province: function(val, oldVal) {
                this.city = '';
                this.handleCityChange(this.city);
                this.citiesList = getCitiesByProvince(val);
            }
        },
        methods: {
            handleCityChange(val) {
                this.$emit('city-select', {
                    province: this.province,
                    city: val
                });
            },
            selectHotCity(code) {
                this.province = getProvinceByCity(code).id;
                this.$nextTick(() =>{
                    this.city = code;

                    this.handleCityChange(this.city);
                })
            }
        }
    }
</script>

<style lang="scss">
    @import '#/css/scss/mixins/_clearfix.scss';
    .mod-city-select {
        .hot-city {
            @include clearfix();

            span {
                display: inline-block;
                padding: 0 4px;
                color: $primary;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
</style>
