var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mod-city-select" },
    [
      _c(
        "el-form-item",
        { staticClass: "col-3" },
        [
          _c(
            "el-select",
            {
              model: {
                value: _vm.province,
                callback: function ($$v) {
                  _vm.province = $$v
                },
                expression: "province",
              },
            },
            [
              _c("el-option", { attrs: { label: "请选择", value: "" } }),
              _vm._l(_vm.provincesList, function (value, key) {
                return _c("el-option", {
                  key: "opt1_" + key,
                  attrs: { label: value, value: key },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "col-3", attrs: { required: true, prop: "city" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              on: { change: _vm.handleCityChange },
              model: {
                value: _vm.city,
                callback: function ($$v) {
                  _vm.city = $$v
                },
                expression: "city",
              },
            },
            [
              _c("el-option", { attrs: { label: "请选择", value: "" } }),
              _vm._l(_vm.citiesList, function (item, index) {
                return _c("el-option", {
                  key: "opt2_" + index,
                  attrs: { label: item.text, value: item.id },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "hot-city" },
        _vm._l(_vm.hotCityMap, function (city, key) {
          return _c(
            "span",
            {
              key: key,
              on: {
                click: function ($event) {
                  return _vm.selectHotCity(city.value)
                },
              },
            },
            [_vm._v(_vm._s(city.label))]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }